import React, { useEffect } from 'react'
import SEO from '../components/seo'
import Divider from '../components/divider'

const AboutPage = () => {
    useEffect(() => {
        document.body.classList.add('about-page');
        return () => {
            document.body.classList.remove('about-page');
        }
    }, [])
    return (
        <>
        <SEO title="About" /> 
        
            <h2 className="page-title about-color">About Me</h2>
            <div className="about-text">
                <div className="about-intro">
            <p>Hi, I'm Kevin.</p>
            <p>I am a multi-disciplined designer that has been working in the field for nearly 20 years. 
                I have spent much of that time working with web technology from the early days of building websites with slice and dice tools, to working on 
                large scale web applications with a team of developers. Between those points I have experienced a wide range of opportunities: 
                ad creation in print and web, print media for any number of needs, video production and editing, branding and identity, project management, CMS integration, 
                custom solutions design and development, website and web application management, design system creation, re-branding, re-design, 
                and re-factoring galore.
            </p>
            <p>
                For the past few years I have been getting more and more involved with front-end development as an offshoot of my work in UI and UX design. I kind of figured that
                if I was going to be designing these things, I should know how to build them too. This site is also a byproduct of wanting to be a better developer. My goal is to use
                this as a platform for exploring tools and techniques while keeping track of some of the other things that interest me. 
            </p>
                </div>
            <div className="about-interlude">
            <h3 className="interlude about-color">
                Speaking of...
            </h3>
            <p>I am still interested in all of those things above, however I have also started to branch out into more of the maker space as well. I have a strong interest in 
                woodworking, metal working, and really just building things. I find it very satisfying to be able to build something and then stand back and enjoy it. 
            </p>
            <p>
                Right now my aspirations on this front outstretch my tools, but no matter. I am exploring and having some much needed fun.
            </p>
            <p>
                    That is about it for now. At least in terms of things that I am going to get into on this page. <span role="img" aria-label="wink" className="emoji">😉</span>
            </p>
                </div>

            

            <div className="site-info">
                <h3 className="about-color">About This Site</h3>
                <p>This site is built with <a href="http://gatsbyjs.org/" rel="external">GatsbyJS</a> and deployed via <a href="https://www.netlify.com" rel="external">Netlify</a>. 
                I did not want the hassle of dealing with or paying for servers, so I thought I would give serverless a try.</p>
                <p>Verdict? So far, so good.</p>
            </div>
            </div>
        
        </>
    )
}

export default AboutPage
